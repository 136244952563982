import { darken } from '@material-ui/core/styles/colorManipulator';
import type { Theme } from '@material-ui/core';
import { TOOLBAR_HEIGHT } from '../../layouts/GuestPortal/components/Topbar';

const FORM_PADDING = 20;

const styles = (theme: Theme) => {
    const ROOT_BOTTOM_PADDING = theme.spacing(3)
    return ({
        root: {
            // backgroundColor: theme.palette.common.white,
            display: 'flex',
            zIndex: -10,
            flexDirection: 'column',
            height: 'inherit',
            // paddingLeft: theme.spacing.unit,
            // paddingRight: theme.spacing.unit,
            paddingBottom: ROOT_BOTTOM_PADDING,
        },
        darkerLink: {
            color: darken(theme.palette.primary.main, 0.2),
            textShadow: '0.1px 0.2px black',
        },
        hidden: {
            display: 'none',
        },
        paper: {
            padding: 25,
        },
        mute: {
            color: theme.palette.text.secondary,
        },
        accordionSummary: {
            width: '100%',
            display: 'flex',
        },
        error: {
            color: theme.palette.error.main,
        },
        accordionError: {
            color: theme.palette.error.main,
            marginLeft: 'auto',
        },
        accordionMuted: {
            color: theme.palette.text.secondary,
            marginLeft: 'auto',
        },
        card: {
            // maxWidth: 345,
        },
        media: {
            paddingTop: 0,
            objectFit: 'cover',
            width: '100%',
        },
        grid: {
            height: '100%',
        },
        bookingSourceLogoMobile: {
            maxWidth: '120px',
            marginBottom: '20px',
        },
        bookingSourceLogoDesktop: {
            maxWidth: '300px',
            marginBottom: '20px',
        },
        form: {
            padding: `${FORM_PADDING}px`,
            zIndex: 1,
        },
        textCenterAlign: {
            textAlign: 'center',
        },
        checkInOutTitle: {
            fontWeight: 'bolder',
        },
        typographyIcon: {
            display: 'flex',
            alignItems: 'center',
        },
        typographyIconSubtitle1: {
            position: 'relative',
            top: theme.spacing(1) - 6,
            width: theme.typography.subtitle1.fontSize,
            height: theme.typography.subtitle1.fontSize,
        },
        typographyIconBody1: {
            position: 'relative',
            top: theme.spacing(1) - 6,
            width: theme.typography.body1.fontSize,
            height: theme.typography.body1.fontSize,
        },
        typographyIconBody2: {
            position: 'relative',
            top: theme.spacing(1) - 6,
            width: theme.typography.body2.fontSize,
            height: theme.typography.body2.fontSize,
        },
        iconMoon: {
            transform: 'rotate(345deg) scaleX(-1)',
        },
        submitEditButton: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
        },
        submitButtonByText: {
            width: '70%',
            marginLeft: theme.spacing(1),
        },
        editButton: {
            marginLeft: 'auto',
        },
        linkButton: {
            cursor: 'pointer',
            color: theme.palette.primary.main,
            textDecoration: 'underline',
        },
        iconInButton: {
            marginRight: theme.spacing(1),
        },
        gridButton: {
            display: 'block',
            marginLeft: 'auto',
        },
        gridDivider: {
            width: '100%',
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },

        fieldBox: {
            width: '100%',
            display: 'flex',
        },
        fieldBoxText: {
            paddingTop: theme.spacing(1),
        },
        fieldBoxButton: {
            marginLeft: 'auto',
        },
        svgIconLineHeight: {
            lineHeight: '22px',
            padding: '2px',
        },

        guestListRemoveButton: {
            marginLeft: 'auto',
        },
        guestListItemTitle: {
            marginTop: 'auto',
            marginBottom: 'auto',
        },
        guestListRemoveDiv: {
            display: 'flex',
            width: '100%',
        },

        usageAgreementScroll: {
            maxHeight: `calc(100vh - ${TOOLBAR_HEIGHT}px - ${FORM_PADDING * 2}px - ${ROOT_BOTTOM_PADDING}px)`,
            overflow: 'scroll',
            overflowX: 'hidden',
            paddingRight: '10px',
        },

        purposeStayButtonsDiv: {},
        purposeStayButtons: {
            fontWeight: '400',
            display: 'inline-block',
            marginTop: '10px',
            marginRight: '10px',
            padding: '8px 16px',
            textTransform: 'none',
        },
        purposeStayButtonUnselected: {
            backgroundColor: '#f5f5f5',
        },
        purposeStayButtonSelected: {
            backgroundColor: '#a3eea68f !important',
            color: theme.palette.success.main,
            '&:hover': {
                backgroundColor: '#a3eea68f',
            },
        },

        signature: {
            fontFamily: '"Freehand"',
            fontSize: '1.5rem',
        },

        chips: {
            display: 'flex',
            justifyContent: 'left',
            flexWrap: 'wrap',
            '& > *': {
                margin: theme.spacing(0.5),
            },
        },

        idVerifyHelpImage: {
            width: '100%',
        },
        idVerifyHelpImageSmall: {
            height: '120px',
            display: 'block',
            margin: 'auto',
        },
        idVerifyCapturedImage: {
            width: '100%',
        },
        idVerifyProcessing: {
            height: '273px',
            textAlign: 'center',
            padding: '20px',
        },
        idVerifyWarningIcon: {
            backgroundColor: theme.palette.warning.main,
        },
        selfieImage: {
            display: 'block',
            margin: 'auto',
        },

        finishTextColor: {
            color: theme.palette.common.white,
        },
        finishMainTextBox: {
            zIndex: 0,
            width: '100%',
            padding: '50px 30px 50px 0px',
        },
        finishMainTextBoxSubtitle: {
            color: theme.palette.common.white,
        },
        finishInformationBox: {
            position: 'fixed',
            bottom: '50px',
        },
        embedded: {
            padding: '0px',
            paddingTop: '0px',
            paddingRight: '20px',
            paddingLeft: '20px',
            paddingBottom: '20px',
        },
    })
};

export type GuestPortalClasses = keyof ReturnType<typeof styles>

export default styles;
