// Material components
import {IconButton, Toolbar} from '@material-ui/core';
import type {Theme} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
// Material icons
import {ChevronLeft as ChevronLeftIcon, Clear as CloseIcon, InfoOutlined as InfoIcon} from '@material-ui/icons';
// Externals
import classNames from 'classnames';
import React from 'react';
// Components
import CDNImage from '../../../../components/CDNImage';
import type {TopbarProps} from './types/index';

export const TOOLBAR_HEIGHT = 70;

// Component styles
const useStyles = makeStyles((theme: Theme) => ({
    root: {
        // @ts-ignore palette.border DNE on Theme type yet
        borderBottom: `1px solid ${theme.palette.border}`,
        display: 'flex',
        alignItems: 'center',
        height: '64px',
        zIndex: theme.zIndex.appBar,
    },
    toolbar: {
        minHeight: 'auto',
        width: '100%',
        height: `${TOOLBAR_HEIGHT}px`,
    },
    title: {
        marginLeft: theme.spacing(1),
    },
    menuButton: {
        marginLeft: '-4px',
        color: theme.palette.common.white,
    },
    menuButtonBlack: {
        marginLeft: '-4px',
        color: theme.palette.text.secondary,
    },
    notificationsButton: {
        marginLeft: 'auto',
    },
    bookingSourceImage: {
        maxWidth: '120px',
        maxHeight: '100%',
    },
    infoButton: {
        marginLeft: 'auto',
        color: theme.palette.common.white,
    },
    infoButtonBlack: {
        marginLeft: 'auto',
        color: theme.palette.text.secondary,
    },
}));

const Topbar: React.FC<TopbarProps> = (props) => {
    const {rootProps, bookingSourceImage, onBack, onInfo, isDesktop = false, overlay, reservation} = props;

    const classes = useStyles();

    let backIcon = <ChevronLeftIcon />;
    if (overlay && overlay === 'Help') {
        backIcon = <CloseIcon />;
    }

    return (
        <div {...rootProps}>
            <Toolbar className={classes.toolbar}>
                {onBack && (
                    <IconButton
                        className={classNames(classes.menuButton, {[classes.menuButtonBlack]: isDesktop})}
                        onClick={typeof onBack === 'function' ? onBack : undefined}
                        href={''}
                    >
                        {backIcon}
                    </IconButton>
                )}
                {bookingSourceImage && (
                    <CDNImage
                        src={bookingSourceImage}
                        userId={reservation.hid || undefined}
                        size="original"
                        filename="secondary"
                        className={classes.bookingSourceImage}
                    />
                )}
                {onInfo && (
                    <IconButton
                        className={classNames(classes.infoButton, {[classes.infoButtonBlack]: isDesktop})}
                        onClick={typeof onInfo === 'function' ? onInfo : undefined}
                        href={''}
                    >
                        <InfoIcon />
                    </IconButton>
                )}
            </Toolbar>
        </div>
    );
};

export default Topbar;
