import React from 'react';
import config from '../config';
import type {CDNImageProps} from './types/CDNImage';

const getCdnUrl = ({src, listingId, userId, size = 'original', filename = 'primary'}: CDNImageProps) => {
    let cdnUrl;
    const srcParts = `${src}`.split('.');
    const extension = srcParts[srcParts.length - 1];
    const baseCDN = `https://cdn.${config.isProd ? 'snapcheck' : 'gueststay'}.in`;
    if (listingId) {
        cdnUrl = `${baseCDN}/userdata/images/listing/${listingId}/${userId}/${size}.${extension}`;
    }
    if (!listingId && userId) {
        cdnUrl = `${baseCDN}/userdata/images/logo/${userId}/${size}.${filename}.${extension}`;
    }
    return cdnUrl;
};

const CDNImage = React.forwardRef<HTMLImageElement, CDNImageProps>(function CDNImage(props, _ref) {
    const {src, listingId, userId, size = 'original', filename = 'primary', ...options} = props;
    const cdnUrl = getCdnUrl(props);
    const [imgsrc, setImgSrc] = React.useState(cdnUrl || src);
    const handleImageError = () => {
        console.log(`CDNImage failed to load ${cdnUrl}`);
        if (imgsrc !== src) {
            setImgSrc(src);
        }
    };

    const imageProps = {
        ...options,
        src: imgsrc,
        onError: handleImageError,
    };

    return <img {...imageProps} />;
});

export {getCdnUrl};
export default CDNImage;
